import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { useEffect } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -2.5rem;
  top: 3rem;
  bottom: 6rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    right: 2rem;
    top: 6rem;
    bottom: 9rem;
  }
`;

const ProgressWrapper = styled.div`
  flex: 1;
  width: 3px;
  background: ${({ theme }) => transparentize(0.7, theme.grey)};
`;

const ProgressBar = styled(motion.div)`
  background: ${({ theme }) => theme.grey};
  width: 100%;
`;
const Step = styled.p`
  transform: rotate(90deg);
  width: 5rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  transform-origin: center left;
`;

const SliderNav = ({ details }) => {
  const [progress, setProgress] = useState(0);
  const [step, seStep] = useState(null);

  useEffect(() => {
    if (!details) return;

    const percentage = Math.round(details.progressSlides * 100);
    if (percentage >= 0 && percentage <= 100) setProgress(percentage);
    seStep(`${details.absoluteSlide + 1} / ${details.size}`);
  }, [details]);

  return (
    <Wrapper>
      <ProgressWrapper>
        <ProgressBar animate={{ height: `${progress}%` }} />
      </ProgressWrapper>
      {step && <Step>{step}</Step>}
    </Wrapper>
  );
};

export default SliderNav;
