import React from 'react';
import styled from 'styled-components';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import useDeviceDetection from '../hooks/useDeviceDetection';

import Card from './Card';
import Container from './Container';
import { SectionWrapper } from './SectionWrapper';
import ProjectsJSON from '../../static/data/projects.json';

const StyledSectionWrapper = styled(SectionWrapper)`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 0;
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    margin: 0;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Column = styled(motion.div)`
  flex: 0 0 100%;

  &:first-child {
    @media (${({ theme }) => theme.respondTo.mobile}) {
      margin: 0 0 4rem;
    }
  }

  @media (${({ theme }) => theme.respondTo.tabletL}) {
    flex: 1;

    &:first-child {
      margin: 8rem 4rem 0 0;
    }
    &:last-child {
      margin: 0 0 0 4rem;
    }
  }
`;

const Projects = () => {
  const { scrollY } = useViewportScroll();
  const { isDesktop } = useDeviceDetection();
  const y = useTransform(scrollY, value => -(value / 10));

  const transition = {
    ease: [0.165, 0.84, 0.44, 1],
    duration: 1.75,
  };

  return (
    <StyledSectionWrapper>
      <Container>
        <Columns>
          <Column
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ ...transition, delay: 0.25 }}
          >
            {ProjectsJSON.filter((a, b) => b < 2).map(project => (
              <Card key={project.title} {...project} />
            ))}
          </Column>
          <Column
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ ...transition }}
            style={isDesktop ? { y } : {}}
          >
            {ProjectsJSON.filter((a, b) => b > 1).map(project => (
              <Card key={project.title} {...project} />
            ))}
          </Column>
        </Columns>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Projects;
