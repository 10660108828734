import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { SectionWrapper } from './SectionWrapper';
import Container from './Container';
import Title from './Title';
import Text from './Text';

const Wrapper = styled.div`
  display: flex;
  margin: 8.3rem 0 0;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    margin: 4.8rem 0 0;
    justify-content: space-between;
  }
`;

const CTAWrapper = styled.div`
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 0 18rem 0 0;
  }
  @media (${({ theme }) => theme.respondTo.mobile}) {
    p,
    a {
      font-size: 1.6rem;
    }
  }
`;

const Contact = () => {
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          document.querySelector('body').classList.add('theme-dark');
        }, 500)
      } else {
        document.querySelector('body').classList.remove('theme-dark');
      }
    },{
      root: null,
      rootMargin: "0px",
      threshold: 0.9
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);


  return (
    <SectionWrapper ref={ref}>
      <Container>
        <Title big>
          Een project in gedachten? <br />
          Let's chat!
        </Title>
        <Wrapper>
          <CTAWrapper>
            <Text bold>E-mailadres</Text>
            <Text as="a" href="mailto:info@studiodrieluik.nl">
              info@studiodrieluik.nl
            </Text>
          </CTAWrapper>
          <CTAWrapper>
            <Text bold>Telefoon</Text>
            <Text as="a" href="tel:0031681791358">
              +31 6 8179 1358
            </Text>
          </CTAWrapper>
        </Wrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Contact;
