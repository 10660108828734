import styled from 'styled-components';

export const SectionWrapper = styled.section`
  margin: 0 0 6.4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 0 0 16.4rem;
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    margin: 0 0 24rem;
  }

`;
