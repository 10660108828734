import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import Text from './Text';
import Image from './Image';

const Wrapper = styled.div`
  margin: 0 0 4rem 0;

  &:not(:last-child):not(:only-child) {
    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin: 0 0 12rem 0;
    }
  }

  img {
    width: 100%;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  padding-bottom: 100%;
  height: 0;

  a:hover,
  a:focus {
    .gatsby-image-wrapper {
      transform: scale(0.95);
      overflow: hidden;
    }

    img {
      transform: scale(1.15) !important;
    }
  }

  .gatsby-image-wrapper {
    transition: transform ${({ theme }) => theme.transitionSpeed}
      ${({ theme }) => theme.transitionSetting} !important;

    > div {
      padding-bottom: 100% !important;
    }
  }

  img {
    transition: all ${({ theme }) => theme.transitionSpeed}
      ${({ theme }) => theme.transitionSetting} !important;
  }
`;

const Content = styled.div`
  margin: 2.4rem 0 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 3.2rem 0 0;
  }

  ${Text} {
    margin-top: 0.8rem;
    line-height: 1.5;
  }

  span {
    font-weight: normal;
    font-size: 1.6rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      font-size: 1.8rem;
    }
  }
`;

const Card = ({ image, title, subtitle, type, url }) => {
  if (url) {
    image = (
      <a href={url} target="_blank" rel="noopener nofollow noreferrer">
        <Image src={image.src} alt={image.alt} />
      </a>
    );
  } else {
    image = <Image src={image.src} alt={image.alt} />;
  }

  return (
    <Wrapper>
      <ImgWrapper>{image}</ImgWrapper>

      <Content>
        <Title small bold>
          <a href={url} target="_blank" rel="noopener nofollow noreferrer">
            {title}
          </a>
        </Title>
        <Text big medium>
          {subtitle} <span>— {type}</span>
        </Text>
      </Content>
    </Wrapper>
  );
};

export default Card;
