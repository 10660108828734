import React from 'react';
import styled from 'styled-components';
import Text from './Text';
import Title from './Title';
import Container from './Container';
import Slider from './Slider';
import { SectionWrapper } from './SectionWrapper';
import TestimonialsJSON from '../../static/data/testimonials.json';

const Wrapper = styled.div`
  max-width: 80rem;
  margin: 0 0 6.4rem;
`;

const Testimonials = () => {
  return (
    <SectionWrapper>
      <Container>
        <Wrapper>
          <Title big>Wat anderen zeggen</Title>
          <Text>
            Hoe mooi wij het ook zelf kunnen vertellen; er gaat niets boven
            echte recensies. Onderstaand een greep uit van wat onze
            opdrachtgevers van de samenwerking met ons vonden.
          </Text>
        </Wrapper>
        <Slider items={TestimonialsJSON} />
      </Container>
    </SectionWrapper>
  );
};

export default Testimonials;
