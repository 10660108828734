import styled from 'styled-components';

const Text = styled.p`
  font-size: ${({ big }) => (big ? '1.8rem' : '1.6rem')};
	font-weight: ${({ medium, bold }) => (medium ? 600 : bold ? 800 : 'normal')};
	color: ${({ theme }) => theme.darkGrey};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: ${({ big }) => (big ? '2rem' : '1.8rem')};
  }
`;

export default Text;
