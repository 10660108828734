import React from 'react';
import styled from 'styled-components';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import SliderItem from './SliderItem';
import SliderNav from './SliderNav';
import { useState } from 'react';

const Wrapper = styled.div`
  position: relative;
  height: 39rem;
`;

const Slider = ({ items }) => {
  const [sliderDetails, setSliderDetails] = useState(null);
  const [sliderRef] = useKeenSlider({
    spacing: 2,
    slidesPerView: 1,
    vertical: true,
    move: slider => setSliderDetails(slider.details()),
  });

  return (
    <Wrapper ref={sliderRef} className="keen-slider keen-slider--vertical">
      {items.map((item, i) => (
        <SliderItem key={i} item={item} />
      ))}
      <SliderNav details={sliderDetails} />
    </Wrapper>
  );
};

export default Slider;
