import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import Text from './Text';
import BrushSVG from '../../static/images/brush.svg';

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkGrey};
  padding: 1.6rem 5.2rem 1.6rem 2.4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 8rem 4.8rem 8rem 16rem;
  }

  .theme-dark & {
    background: ${({ theme }) => theme.darkGrey};
    transition: background ${({ theme }) => theme.transitionSpeed}
      ${({ theme }) => theme.transitionSetting};
  }
`;

const TextWrapper = styled.div`
  position: relative;
  max-width: 70rem;

  svg {
    position: absolute;
    top: -5rem;
    left: -4rem;
    z-index: -1;
    transition: opacity ${({ theme }) => theme.transitionSpeed}
      ${({ theme }) => theme.transitionSetting};

    &:nth-child(2) {
      left: 6rem;
    }

    .theme-dark & {
      opacity: 0.1;
    }
  }
`;

const Person = styled.div`
  display: flex;
  align-items: center;
  margin: 2.4rem 0 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 4.8rem 0 0;
  }
`;

const StyledText = styled(Text)`
  font-size: 1.6rem;
  margin: 0;

  &:last-child:not(:only-child) {
    margin: 0;
    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin: 0.8rem 0 0;
    }
  }
`;

const Img = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 1.6rem 0 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 6.4rem;
    height: 6.4rem;
    margin: 0 2.4rem 0 0;
  }
`;

const SliderItem = ({ item }) => {
  const { text, person } = item;
  return (
    <Slide className="keen-slider__slide">
      <TextWrapper>
        <Text medium>{text}</Text>
        <SVG src={BrushSVG} />
        <SVG src={BrushSVG} />
      </TextWrapper>
      <Person>
        <Img
          src={require(`../../static/images/${person.image}`)}
          alt={person.name}
        />
        <div>
          <StyledText medium>{person.name}</StyledText>
          <StyledText>{person.subtitle}</StyledText>
        </div>
      </Person>
    </Slide>
  );
};

export default SliderItem;
