import styled from 'styled-components';

const Title = styled.h2`
  font-size: ${({ big }) => (big ? '3rem' : '2.4rem')};
  font-weight: bold;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: ${({ big }) => (big ? '4rem' : '3rem')};
  }

  + * {
    margin-top: ${({ big }) => (big ? '2.4rem' : '1.6rem')};
  }
`;

export default Title;
