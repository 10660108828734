import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Container from './Container';
import Text from './Text';
import { SectionWrapper } from './SectionWrapper';

const Wrapper = styled(motion.div)`
  max-width: 80rem;
  margin: 20rem 0 0;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 35rem 0 0;
  }
`;

const Heading = styled.h1`
  font-size: 3.2rem;
  font-weight: bold;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 4.8rem;
  }
`;

const StyledText = styled(Text)`
  margin: 5rem 0 0;
  line-height: 1.5;
  max-width: 70rem;
  font-weight: normal;
`;

const Hero = ({ introIsFinished }) => {
  return (
    <SectionWrapper>
      <Container>
        <Wrapper
          initial={{ opacity: 0, y: 100 }}
          animate={introIsFinished ? { opacity: 1, y: 0 } : {}}
          transition={{
            ease: [0.165, 0.84, 0.44, 1],
            duration: 1.75,
          }}
        >
          <Heading>
            Wij zijn Studio Drieluik. Een digitaal bureau anno nu, met de blik
            op morgen.
          </Heading>
          <StyledText big medium>
            Met specialisaties in design & development helpen wij opdrachtgevers
            hun plek te vinden in het digitale landschap.
          </StyledText>
        </Wrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Hero;
