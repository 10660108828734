import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import LogoSVG from '../../static/images/logo.svg';
import Container from './Container';
import Text from './Text';

const Wrapper = styled.footer`
  padding: 0 0 6.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    padding: 4.8rem 0 1.6rem;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
  }
`;

const StyledText = styled(Text)`
  @media (${({ theme }) => theme.respondTo.mobile}) {
    font-size: 1.6rem;
    margin: 0 auto;
    max-width: 80%;
  }
`;

const Logo = styled(SVG)`
  margin: 0 0 1.6rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    position: absolute;
    left: 0;
  }
`;

const Footer = () => (
  <footer>
    <Container>
      <Wrapper>
        <Logo src={LogoSVG} />
        <StyledText>
          Met passie en vakmanschap — Studio Drieluik © 2022
        </StyledText>
      </Wrapper>
    </Container>
  </footer>
);

export default Footer;
