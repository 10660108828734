import React, { useState } from 'react';
import Layout from '../layouts/default';
import AnimatedLogo from '../components/AnimatedLogo';
import Hero from '../components/Hero';
import Projects from '../components/Projects';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const IndexPage = () => {
  const [introIsFinished, setIntroIsFinised] = useState(false);
  const [introCanBeUmounted, setIntroCanBeUmounted] = useState(false);

  return (
    <Layout>
      <Hero introIsFinished={introIsFinished} />
      {introIsFinished && (
        <>
          <Projects />
          <Testimonials />
          <Contact />
          <Footer />
        </>
      )}
      {!introCanBeUmounted && (
        <AnimatedLogo
          setIntroIsFinised={setIntroIsFinised}
          setIntroCanBeUmounted={setIntroCanBeUmounted}
        />
      )}
    </Layout>
  );
};

export default IndexPage;
