import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { withTheme } from 'styled-components';
import SVG from 'react-inlinesvg';
import useDeviceDetection from '../hooks/useDeviceDetection';
import Logo from '../../static/images/animated-logo.svg';

const Wrapper = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  background: black;

  > svg {
    width: 10rem;
    height: auto;
  }

  .svg-path {
    stroke-dasharray: 264.87335205078125px;
    stroke-dashoffset: ${({ isMounted }) =>
      isMounted ? 0 : '264.87335205078125px'};
    transition: stroke-dashoffset 10s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }

  .svg-path-bg {
    stroke-dasharray: 264.87335205078125px;
    stroke-dashoffset: 0;
    opacity: ${({ isMounted }) => (isMounted ? 0.2 : 0)};
    transition: opacity 1s ease;
  }

  #sd-logo {
    stroke: ${({ isMounted }) => (isMounted ? 'white' : 'black')};
    transition: stroke 5s cubic-bezier(0.19, 1, 0.22, 1);
  }
`;

const AnimatedLogo = ({ setIntroIsFinised, setIntroCanBeUmounted, theme }) => {
  const [isMounted, setIsMounted] = useState(false);
  const { isDesktop } = useDeviceDetection();

  useEffect(() => {
    setTimeout(() => setIsMounted(true), 500);
    setTimeout(() => setIntroIsFinised(true), 2750);
  }, [setIntroIsFinised]);

  const transition = {
    delay: 2.5,
    stiffness: 50,
    damping: 100,
  };

  const variants = {
    animate: {
      scale: isDesktop ? 4 : 2,
      opacity: 0,
      transition: {
        scale: {
          // type: 'spring',
          duration: 1,
          ...transition,
        },
        opacity: {
          // type: 'spring',
          duration: 0.4,
          ...transition,
        },
      },
    },
  };

  return (
    <Wrapper
      variants={variants}
      animate="animate"
      isMounted={isMounted}
      onAnimationComplete={() => setIntroCanBeUmounted(true)}
    >
      <SVG src={Logo} />
    </Wrapper>
  );
};

export default withTheme(AnimatedLogo);
